import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RewardType } from "../constants";

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={`redeemPrev slickBtn ${className}`}
            onClick={onClick}
        >
            <button className="btn btn-primary" type="button">
                <i className="fas fa-chevron-left"></i>
            </button>
        </div>
    );
}

function NextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={`redeemNext slickBtn ${className}`}
            onClick={onClick}
        >
            <button className="btn btn-primary" type="button">
                <i className="fas fa-chevron-right"></i>
            </button>
        </div>
    );
}

const SimpleSlider = (state) => {
    const slider = React.useRef(null);
    const [rewards, setRewards] = useState([]);
    const [hideDescription, setHideDescription] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setRewards(state.rewards);
    }, [state.rewards])

    useEffect(() => {
        setHideDescription(state.hideDescription);
    }, [state.hideDescription])

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ],
    };
    return (
        <>
            {rewards && rewards.length > 0 && <Slider
                ref={slider}
                {...settings}
            >
                {/* When displaying a card for each item, the actually used fields under item are: id, icon (or image), name, description, points (optional) */}
                {rewards.map((reward, index) => (
                    <div className="redeemSliderItem" key={index}>
                        <div className="redeemItem m-0 m-sm-3">

                            <Link
                                to={`/${i18n.language}/${reward.rewardType == RewardType.EVENT ? "event" : /* default to item */ "reward-detail"}/${reward?.alias}`} 
                                state={{ item: reward }}>

                                <div className="imgs">
                                    <img className="img-fluid" src={
                                        (reward?.icon && reward?.icon[i18n.language]) ?
                                            reward?.icon[i18n.language] :
                                            (
                                                reward?.image_url ?
                                                    reward?.image_url[i18n.language] :
                                                    reward?.image?.path
                                            )
                                    } />
                                </div>

                                {!hideDescription &&
                                    <div className="desc">
                                        <h5 className="title">{reward.name[i18n.language] || reward.name}</h5>
                                            <p className="points">
                                            { reward.points > 0 &&
                                                reward.points.toLocaleString() + " " + t('offer_convert_to_miles.points')
                                            }
                                            </p>
                                        <div className="cons p" dangerouslySetInnerHTML={{ __html: reward.short_description[i18n.language] || reward.short_description['en'] }}></div>
                                    </div>
                                }
                            </Link>
                        </div>
                    </div>
                ))}
            </Slider>}
        </>
    );
}

export default SimpleSlider;

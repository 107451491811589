import axios, { AxiosResponse } from 'axios';
import { getJwtToken } from '../services/user';

const METHOD_POST = 'post';
const METHOD_GET = 'get';
const METHOD_PATCH = 'patch';
const METHOD_DELETE = 'delete';
export const exceptionStatusCodes = [400, 404, 500, 504];
const API_LIST: { [endpoint: string]: { method: string, url: string, jwt_auth: boolean, headers?: {}, with_credentials?: boolean } } = {
    // Auth API
    AUTH_REGISTER: {
        method: METHOD_POST,
        url: '/api/auth/register',
        jwt_auth: false,
    },
    AUTH_ACCOUNT_ACTIVATION: {
        method: METHOD_POST,
        url: '/api/auth/account-activation',
        jwt_auth: false,
    },
    AUTH_LOGIN_WITH_EMAIL: {
        method: METHOD_POST,
        url: '/api/auth/login',
        jwt_auth: false,
    },
    AUTH_LOGIN_WITH_GOOGLE: {
        method: METHOD_POST,
        url: '/api/public/google-login',
        jwt_auth: false,
    },
    AUTH_LOGIN_WITH_FACEBOOK: {
        method: METHOD_POST,
        url: '/api/public/facebook-login',
        jwt_auth: false,
    },
    AUTH_GET_USER: {
        method: METHOD_GET,
        url: '/api/hkphil/users/me',
        jwt_auth: true,
    },
    AUTH_REFRESH_TOKEN: {
        method: METHOD_POST,
        url: '/api/auth/refresh',
        jwt_auth: true,
    },
    AUTH_FORGET_PASSWORD: {
        method: METHOD_POST,
        url: '/api/auth/forgot-password',
        jwt_auth: true,
    },
    AUTH_RESET_PASSWORD: {
        method: METHOD_POST,
        url: '/api/auth/reset-password',
        jwt_auth: true,
    },
    AUTH_LOGOUT: {
        method: METHOD_POST,
        url: '/api/auth/logout',
        jwt_auth: true,
    },
    CREATE_TIER_PAYMENT: {
        method: METHOD_POST,
        url: '/api/hkphil/tier-payment',
        jwt_auth: true,
    },
    CREATE_YA_PAYMENT: {
        method: METHOD_POST,
        url: '/api/hkphil/ya-payment',
        jwt_auth: true,
    },
    GET_USER_INTERESTS: {
        method: METHOD_POST,
        url: '/api/hkphil/users/interests',
        jwt_auth: true,
    },
    GET_USER_POINT_TRANSACTION: {
        method: METHOD_GET,
        url: '/api/hkphil/users/point-transactions',
        jwt_auth: true,
    },
    GET_USER_TIER_UPGRADE_PROGRESS: {
        method: METHOD_POST,
        url: '/api/hkphil/users/tier-progress',
        jwt_auth: true,
    },
    GET_EXISTING_MEMBER_PROFILE: {
        method: METHOD_POST,
        url: '/api/hkphil/users/existing-profile',
        jwt_auth: true,
    },
    GET_SC_TIER: {
        method: METHOD_POST,
        url: '/api/hkphil/users/get-sc-tier',
        jwt_auth: true,
    },
    GET_FREE_UPGRADE_TIER: {
        method: METHOD_POST,
        url: '/api/hkphil/users/get-free-upgrade-tier',
        jwt_auth: true,
    },
    CREATE_ASIA_MILES_REDEEM_RECORD: {
        method: METHOD_POST,
        url: '/api/hkphil/users/redeem-asia-miles',
        jwt_auth: true,
    },
    SEND_WELCOME_EMAIL: {
        method: METHOD_POST,
        url: '/api/hkphil/welcome',
        jwt_auth: true,
    },
    UPDATE_USER_EMAIL_AND_MEMBER_NO: {
        method: METHOD_POST,
        url: '/api/hkphil/users/email-and-member-no',
        jwt_auth: true,
    },
    UPDATE_USER_EMAIL_VERIFIED: {
        method: METHOD_POST,
        url: '/api/hkphil/users/email-verified',
        jwt_auth: true,
    },
    UPDATE_MEMBER_PROFILE: {
        method: METHOD_POST,
        url: '/api/hkphil/users/update-profile',
        jwt_auth: true
    },
    SAVE_CHOSEN_REGISTRATION_FORM_TYPE: {
        method: METHOD_POST,
        url: '/api/hkphil/users/save-chosen-registration-form',
        jwt_auth: true
    },

    SCAN_TICKET: {
        method: METHOD_POST,
        url: '/api/hkphil/scan-ticket',
        jwt_auth: true
    },
    VERIFY_SCAN_TICKET: {
        method: METHOD_POST,
        url: '/api/hkphil/verify-ticket',
        jwt_auth: true
    },
    GET_USER_REDEEMED_ITEM_NUMBER: {
        method: METHOD_POST,
        url: '/api/hkphil/redeemed-item-number',
        jwt_auth: true,
    },
    REDEEM_ITEM: {
        method: METHOD_POST,
        url: '/api/hkphil/redeem',
        jwt_auth: true,
    },
    REGISTER_EVENT: {
        method: METHOD_POST,
        url: '/api/hkphil/register-event',
        jwt_auth: true,
    },
    GET_GAUGE_LEVELS: {
        method: METHOD_GET,
        url: '/api/hkphil/get-gauge-levels',
        jwt_auth: true,
    },

    // Public API
    FIND_OCTOBER_PROFILE: {
        method: METHOD_POST,
        url: '/api/hkphil/users/profile',
        jwt_auth: false,
    },
    SET_SC_TOKEN_IS_USED: {
        method: METHOD_POST,
        url: '/api/hkphil/assign-sc-tier',
        jwt_auth: false,
    },
    SET_FREE_TIER_UPGRADE_TOKEN_IS_USED: {
        method: METHOD_POST,
        url: '/api/hkphil/assign-free-upgrade-tier',
        jwt_auth: false,
    },
    SET_PROFILE_IS_MIGRATED: {
        method: METHOD_POST,
        url: '/api/hkphil/profile-migrated',
        jwt_auth: false,
    },
    PUBLIC_GET_INSTRUMENT_LIST: {
        method: METHOD_GET,
        url: '/api/hkphil/get-instrument-list',
        jwt_auth: false,
    },
    PUBLIC_GET_ALL_REDEEMED_ITEMS: {
        method: METHOD_GET,
        url: '/api/hkphil/list-redeem-items',
        jwt_auth: false,
    },
    PUBLIC_GET_REDEEM_ITEM: {
        method: METHOD_GET,
        url: '/api/hkphil/get-redeem-item',
        jwt_auth: false,
    },
    PUBLIC_GET_REDEEM_ITEM_AVAILABLE_QUANTITY: {
        method: METHOD_POST,
        url: '/api/hkphil/redeemed-item-available',
        jwt_auth: false,
    },
    PUBLIC_GET_EVENT: {
        method: METHOD_GET,
        url: '/api/hkphil/get-event',
        jwt_auth: false,
    },
    PUBLIC_GET_STATIC_CONTENT: {
        method: METHOD_GET,
        url: '/api/hkphil/static-contents',
        jwt_auth: false,
    },
    PUBLIC_GET_HOMEPAGE_POPUP: {
        method: METHOD_GET,
        url: '/api/hkphil/get-homepage-popup',
        jwt_auth: false,
    },
    PUBLIC_VERIFY_EMAIL_OR_MOBILE: {
        method: METHOD_GET,
        url: '/api/hkphil/verify-email-or-mobile',
        jwt_auth: false,
    },
};

const abortRequestController = new Map();

export function abortRequest(key) {
    abortRequestController.get(key)?.abort?.('page unmount');
}

export function makeApiCalls(apiList: Array<{ endpoint: string, data?: any, slugFragments?: { [key: string]: any }, params?: any }>) {
    let calls: any[] = [];
    apiList.forEach(function (call) {
        // prepare api variables
        const apiInfo = API_LIST[call.endpoint];
        const apiData = call.data;
        const apiParam = call.params;
        let apiHeaders = apiInfo.headers || {};
        let apiEndpoint = process.env.REACT_APP_API_HOST + apiInfo.url;

        abortRequest(call.endpoint);
        const ctrl = new AbortController();
        abortRequestController.set(call.endpoint, ctrl);
        const signal = ctrl.signal;

        if (call.slugFragments) {
            for (let key in call.slugFragments) {
                let value = call.slugFragments[key];
                apiEndpoint = apiEndpoint.replace('{' + key + '}', value);
            }
        }

        if (apiInfo.jwt_auth) {
            apiHeaders = Object.assign({}, apiHeaders, { 'Authorization': 'Bearer ' + getJwtToken() });
        }

        // append api call
        if (apiInfo.method == METHOD_POST) {
            calls.push(
                axios.post(apiEndpoint, apiData, { headers: apiHeaders, signal })
            );
        } else if (API_LIST[call.endpoint].method == METHOD_GET) {
            calls.push(
                axios.get(apiEndpoint, { headers: apiHeaders, params: apiParam, signal })
            );
        } else if (apiInfo.method == METHOD_PATCH) {
            calls.push(
                axios.patch(apiEndpoint, apiData, { headers: apiHeaders, signal })
            );
        } else if (apiInfo.method == METHOD_DELETE) {
            calls.push(
                axios.delete(apiEndpoint, { headers: apiHeaders, params: apiParam, signal })
            );
        }
    });
    return axios.all(calls).catch(ex => {
        return [ex.response || ex];
    });
}

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RegistrationForm, { ValidationInterface } from "../components/profile/RegistrationForm";
import { validateDob, validateEmail, validateMobile, validateRequired } from "../services/helpers";
import { useEffect, useState } from "react";
import VerifyEmail from "../components/VerifyEmail";
import { useAuth0 } from "@auth0/auth0-react";
import { MembershipTier } from "../constants";

const RegistrationFree = () => {
    const navigate = useHistory();
    const { user } = useAuth0();
    const { t, i18n } = useTranslation();
    const [isSkip, setIsSkip] = useState(false);
    const [validations, setValidations] = useState<ValidationInterface>({
        _custom: [
            formData => validateDob('date_of_birth', formData.year, formData.month),
        ]
    });

    const afterSubmit = ({ success }) => new Promise<string>(async (resolve) => {
        if (!success) return resolve(null);
        navigate.push(`/${i18n.language}/registration-success`, {
            state: {
                tier: MembershipTier.BLACK,
            }
        });
        return resolve(null);
    });

    const beforeNext = (current, next) => {
        let rules = { ...validations };
        if (next === 2) {
            rules.email = [validateRequired, validateEmail];
            rules.mobile = [validateMobile];
        }
        if (next === 3) {
            rules._custom.push(formData => !formData.consent ? t('error.missing_tnc') : null);
            rules._custom.push(formData => !formData.confirm_email ? t('error.confirmedEmailIsCorrect') : null);
        }
        setValidations(rules);
        return true;
    }

    const beforePrev = (current, prev) => {
        let rules = { ...validations };
        if (prev === 1) {
            delete rules.email;
            delete rules.mobile;
        }
        if (prev === 2) {
            rules._custom.splice(1, 2);
        }
        setValidations(rules);
        return true;
    }

    //set html_title
    useEffect(() => { 
        document.title = t('html_title.registration_free')+' | '+  t('html_title.home');
    }, []);
    useEffect(() => { 
        document.title = t('html_title.registration_free')+' | '+  t('html_title.home');
    }, [i18n.language]);

    return (
        <>
            {/* temp hide verify email box  */}
            {/* {(user?.email_verified || isSkip)
                ? ( */}
                    <RegistrationForm
                        validations={validations}
                        afterSubmit={afterSubmit}
                        beforeNext={beforeNext}
                        beforePrev={beforePrev}
                    />
                {/* ) : (
                    <VerifyEmail
                        email={user?.email}
                        allowSkip={true}
                        onSkip={() => setIsSkip(true)}
                    />
                )} */}
        </>
    );
};

export default RegistrationFree;

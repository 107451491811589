import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { t } from "i18next";
import { useEffect, useState } from "react";
import AsiaPayForm from "../components/AsiaPayForm";
import RegistrationForm, { ValidationInterface } from "../components/profile/RegistrationForm";
import VerifyEmail from "../components/VerifyEmail";
import { useTranslation } from "react-i18next";
import { validateDob, validateEmail, validateMobile, validateRequired } from "../services/helpers";
import { createNewTierPayment } from "../services/payment";
import { AsiaPayFormLang } from "../services/enums";
import {MembershipTier} from "../constants";

const RegistrationRed = () => {
    const navigate = useHistory();
    const { t, i18n } = useTranslation();
    const { user } = useAuth0();
    const [paymentParams, setPaymentParams] = useState(null);
    const [validations, setValidations] = useState<ValidationInterface>({
        salutations: [validateRequired],
        first_name: [validateRequired],
        last_name: [validateRequired],
        gender: [validateRequired],
        _custom: [
            formData => validateRequired('date_of_birth', formData.year, formData.month),
            formData => validateDob('date_of_birth', formData.year, formData.month),
        ]
    });

    const afterSubmit = ({ success }) => new Promise<string>(async (resolve) => {
        if (!success) return resolve(null);

        const res = await createNewTierPayment();
        if (!res.success) return resolve(null);

        setPaymentParams({...res.tier_payment, ...res.payment_gateway_params});

        // make sure giving React enough time to update layout
        setTimeout(() => {
            const button = document.getElementById("pay-btn");
            button.click();
            resolve(null);
        }, 300);
    });

    const beforeNext = (current, next) => {
        if (current === 0) {
            return true;
        }
        let rules = { ...validations };
        if (next === 2) {
            rules.email = [validateRequired, validateEmail];
            rules.mobile = [validateRequired, validateMobile];
            rules.house_or_building_estate = [validateRequired];
            rules.road_or_street = [validateRequired];
            rules.district = [validateRequired];
            rules.country = [validateRequired];
        }
        if (next === 3) {
            rules._custom.push(formData => !formData.consent ? t('error.missing_tnc') : null);
            rules._custom.push(formData => !formData.confirm_email ? t('error.confirmedEmailIsCorrect') : null);
        }
        setValidations(rules);
        return true;
    }

    const beforePrev = (current, prev) => {
        let rules = { ...validations };
        if (prev === 1) {
            delete rules.email;
            delete rules.mobile;
            delete rules.house_or_building_estate;
            delete rules.road_or_street;
            delete rules.district;
            delete rules.country;
        }
        if (prev === 2) {
            rules._custom.splice(2, 2);
        }
        setValidations(rules);
        return true;
    }

    //set html_title
    useEffect(() => { 
        document.title = t('html_title.registration_red')+' | '+  t('html_title.home');
    }, []);
    useEffect(() => { 
        document.title = t('html_title.registration_red')+' | '+  t('html_title.home');
    }, [i18n.language]);

    return (
        <>
            {/* temp hide verify email box  */}
            {/* {!user?.email_verified && (
                <VerifyEmail email={user?.email} />
            )} */}

            {/* {user?.email_verified && ( */}
                <RegistrationForm
                    formTitle={t('registration_common.club_bravo_red_member_registration')}
                    validations={validations}
                    labelsWithStar={{ 'date_of_birth': true }}
                    beforeNext={beforeNext}
                    beforePrev={beforePrev}
                    formContent={t('registration_common.red_member_registration')}
                    afterSubmit={afterSubmit}
                    lastStepButtonLabel={t('registration_common.submit_and_pay')}
                />
            {/* )} */}

            {paymentParams && (
                <AsiaPayForm
                    orderRef={paymentParams.order_ref}
                    amount={paymentParams.amount}
                    lang={AsiaPayFormLang[i18n.language]}
                    payType={paymentParams.pay_type}
                    merchantId={paymentParams.merchant_id}
                    secureHash={paymentParams.secure_hash}
                    isLive={paymentParams.is_live}
                />
            )}
        </>
    );
};

export default RegistrationRed;
